


























import { defineComponent } from 'vue-demi'

export default defineComponent({
  setup(_, { emit }) {
    function addVitrineItem() {
      emit('on-vitrine-item-add-click', false) // TODO
    }
    return {
      addVitrineItem,
    }
  },
})
