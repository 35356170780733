














































import {
  defineComponent, ref, computed, inject, Ref,
} from 'vue-demi'

import { useStore } from '@/store'

import VitrineWorkTypeItem from '@/components/VitrineWorkTypeItem.vue'
import TheVitrineWorkTypesAdd from '@/components/TheVitrineWorkTypesAdd.vue'
import TheVitrineWorkTypesAddItemDialog from '@/components/dialogs/TheVitrineWorkTypesAddItemDialog.vue'
import TheVitrineWorkTypesChangeCostsDialog from '@/components/dialogs/TheVitrineWorkTypesChangeCostsDialog.vue'
import TheCompanyVitrineDialog from '@/components/dialogs/TheCompanyVitrineDialog.vue'
import TheVitrineChooseWorkTypesDialog from '@/components/dialogs/TheVitrineChooseWorkTypesDialog.vue'
import { WorkTypeWithWorks } from '@/types/extended'

export default defineComponent({
  components: {
    VitrineWorkTypeItem,
    TheVitrineWorkTypesAdd,
    TheVitrineWorkTypesAddItemDialog,
    TheVitrineWorkTypesChangeCostsDialog,
    TheCompanyVitrineDialog,
    TheVitrineChooseWorkTypesDialog,
  },

  props: {
    selectedItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const store = useStore()

    const companyId = inject('companyFromPage')

    const items: Ref<WorkTypeWithWorks[]> | undefined = inject('vitrineItems')
    const currentSelectedItems = ref(props.selectedItems)
    const isCurrentUserOwner = computed(() => store.state.Profile.company?.id === companyId || false)

    const isTheVitrineWorkTypesChangeCostsDialogVisible = ref(false)
    const isTheVitrineWorkTypesAddItemDialogVisible = ref(false)
    const isTheVitrineChooseWorkTypesDialogVisible = ref(false)
    const isCompanyVitrineDialogVisible = ref(false)

    const changeCostsItemObject = ref()

    function visibleWorkCategories() {
      if (items && items.value && currentSelectedItems && currentSelectedItems.value) {
        console.log(currentSelectedItems)
        return items.value.filter(item => {
          if (item.works) {
            // eslint-disable-next-line no-restricted-syntax
            for (const elem of item.works) {
              if (currentSelectedItems?.value.findIndex((currentItem: any) => currentItem.id === elem.id) !== -1) {
                return true
              }
            }
          }

          return false
        })
      }
      return []
    }

    function onVitrineItemClick() {
      return false
    }

    function onBtnChangeClick(item: WorkTypeWithWorks) {
      changeCostsItemObject.value = item
      isTheVitrineWorkTypesChangeCostsDialogVisible.value = true
    }

    function onVitrineItemAddClick() {
      console.log(currentSelectedItems.value)
      isTheVitrineChooseWorkTypesDialogVisible.value = true
    }

    function onUpdateWorkTypes(workTypes: Array<{id: number; cost: string}>) {
      emit('save-selected-items', workTypes)
    }

    return {
      currentSelectedItems,
      changeCostsItemObject,

      isTheVitrineWorkTypesChangeCostsDialogVisible,
      isTheVitrineWorkTypesAddItemDialogVisible,
      isTheVitrineChooseWorkTypesDialogVisible,
      isCompanyVitrineDialogVisible,
      isCurrentUserOwner,

      visibleWorkCategories,

      onVitrineItemClick,
      onVitrineItemAddClick,
      onBtnChangeClick,
      onUpdateWorkTypes,
    }
  },
})
