














import {
  ref,
  reactive,
  computed,
  defineComponent,
  onMounted,
  provide,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'
import { useStore } from '@/store'

import { Maybe } from '@/types'
import { WorkTypeWithWorks } from '@/types/extended'

import { workTypesRepository } from '@/repositories/workTypes'
import { companiesProvider } from '@/providers/companies'
import TheVitrineWorkTypeItemsList from '@/components/TheVitrineWorkTypeItemsList.vue'

interface Props extends Record<string, any> {
  readonly companyId: number
  readonly workIds: Array<{id: number; cost: string}>
}

export default defineComponent<Props>({
  props: {
    companyId: {
      type: Number,
      required: true,
    },

    workIds: {
      type: Array,
      required: true,
    },
  },

  components: {
    TheVitrineWorkTypeItemsList,
  },

  setup(props) {
    const store = useStore()
    const toast = useToast()

    const state = reactive({
      modal: false,
      loading: false,
    })

    const userCompanyId = computed(() => store.state.Profile.company?.id)

    const formRef = ref<Maybe<HTMLFormElement>>(null)

    const workTypes = ref<WorkTypeWithWorks[]>()

    provide('vitrineItems', workTypes)
    provide('companyFromPage', props.companyId)

    async function fetchWorksList() {
      state.loading = true
      try {
        const result = await workTypesRepository.findManyWithWorks({})
        workTypes.value = result.filter(type => type.works?.length)
      } catch (error) {
        toast.error(`fetchWorksList: ${error}`)
      } finally {
        state.loading = false
      }
    }

    async function onItemsListSave(items: Array<{id: number; cost: string}>) {
      if (store.state.Profile.company) {
        await companiesProvider.update({ id: userCompanyId.value || -1 }, { works: items })
      }
    }

    onMounted(async () => {
      await fetchWorksList()
    })

    return {
      state,
      formRef,

      workTypes,

      userCompanyId,

      onItemsListSave,
    }
  },
})

