





import { defineComponent } from 'vue-demi'

export default defineComponent({
  setup(props, { emit }) {
    // TODO
    function onClose() {
      emit('update:visible', false)
    }

    return {
      onClose,
    }
  },
})
