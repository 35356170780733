

























































































































































































import { WorkTypeWithWorks } from '@/types/extended'
import {
  defineComponent, inject, ref, Ref, watch,
} from 'vue-demi'

interface Props extends Record<string, any> {
  readonly visible: boolean
  readonly selectedItems: Array<{id: number; cost: string}>
}

export default defineComponent({

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const items: Ref<WorkTypeWithWorks[]> | undefined = inject('vitrineItems')
    const currentSelectedItems: any = ref(props.selectedItems)

    const currentActiveCategory = ref(0)
    const currentActiveCategoryObject = ref(items && items.value ? items.value[0] : { id: -1 })

    function onClose() {
      emit('update:visible', false)
    }

    function checkIfWorkIdSelected(id: number) {
      if (currentSelectedItems && currentSelectedItems.value) {
        return currentSelectedItems?.value.findIndex((elem: any) => elem.id === id) !== -1
      }
      return false
    }

    function getSelectedItemCost(id: number) {
      const index = currentSelectedItems?.value.findIndex((item: any) => id === item.id)
      if (index !== -1 && currentSelectedItems?.value[index].cost !== 'Не установлено') {
        return `${currentSelectedItems?.value[index].cost} ₽` || 'Не установлено'
      }
      return 'Не установлено'
    }

    function chooseActiveCategory(event: number) {
      currentActiveCategory.value = 1
      currentActiveCategoryObject.value = items?.value[event] || { id: -1 }
    }

    function onVitrineItemCheckboxToggle(id: number) {
      if (currentSelectedItems && currentSelectedItems.value && currentSelectedItems.value.findIndex((elem: any) => elem.id === id) !== -1) {
        currentSelectedItems.value = currentSelectedItems.value.filter((elem: {id: number; cost: string}) => elem.id !== id)
      } else {
        currentSelectedItems.value = [...currentSelectedItems.value, { id, cost: 'Не установлено' }]
      }
    }

    function getVitrineItemsForCurrentActiveCategory() {
      if (items && items.value) {
        const res = items?.value.filter(elem => elem.id === currentActiveCategoryObject?.value?.id)

        return res && res.length > 0 ? res[0].works : []
      }
      return false
    }

    function filterChoosenWorkTypes() {
      console.log(currentSelectedItems)
      emit('update:selectedItems', currentSelectedItems.value)
      emit('on-update-choosen-items', currentSelectedItems.value)
      onClose()
    }

    function closeChooseWorkTypesDialog() {
      currentSelectedItems.value = props.selectedItems
      onClose()
    }

    watch(() => items?.value,
      () => {
        if (items && items.value) {
          // eslint-disable-next-line prefer-destructuring
          currentActiveCategoryObject.value = items.value[0]
        }
      })

    return {
      items,
      currentSelectedItems,

      currentActiveCategory,
      currentActiveCategoryObject,

      onClose,
      checkIfWorkIdSelected,
      onVitrineItemCheckboxToggle,
      getVitrineItemsForCurrentActiveCategory,
      chooseActiveCategory,
      filterChoosenWorkTypes,
      getSelectedItemCost,
      closeChooseWorkTypesDialog,
    }
  },
})
