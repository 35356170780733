













































































































import { WorkTypeWithWorks } from '@/types/extended'
import {
  defineComponent, inject, ref, Ref,
} from 'vue-demi'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    changeCostsItemObject: {
      type: Object,
      required: false,
      default: () => { -1 },
    },
    selectedItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    // TODO

    const items: Ref<WorkTypeWithWorks[]> | undefined = inject('vitrineItems')
    const currentSelectedItems: any = ref(props.selectedItems)

    const currentActiveCategory = ref(0)
    const currentActiveCategoryObject = ref(items && items.value ? items.value[0] : { id: -1 })

    function onClose() {
      emit('update:visible', false)
    }

    function checkChoosenItems() {
      if (currentSelectedItems && currentSelectedItems.value && props.changeCostsItemObject) {
        return props.changeCostsItemObject.works.filter((elem: WorkTypeWithWorks) => currentSelectedItems?.value.findIndex((item: any) => elem.id === item.id) !== -1)
      }
      return []
    }

    function onWorkTypesCostChange() {
      emit('update:selectedItems', currentSelectedItems.value)
      emit('on-update-changed-items', currentSelectedItems.value)
      onClose()
    }

    function getCostForChoosenItem(id: number) {
      const index = currentSelectedItems?.value.findIndex((item: any) => id === item.id)
      return currentSelectedItems.value[index].cost
    }

    function changeCostForChoosenItem(id: number, event: number) {
      const index = currentSelectedItems?.value.findIndex((item: any) => id === item.id)
      console.log(event)
      currentSelectedItems.value[index] = { id: currentSelectedItems.value[index].id, cost: event }
    }

    function closeDialog() {
      currentSelectedItems.value = props.selectedItems
      onClose()
    }

    return {
      items,
      currentSelectedItems,

      currentActiveCategory,
      currentActiveCategoryObject,

      onClose,
      checkChoosenItems,
      onWorkTypesCostChange,
      getCostForChoosenItem,
      changeCostForChoosenItem,
      closeDialog,
    }
  },
})
