







































import {
  computed, defineComponent, inject,
} from 'vue-demi'
import { useStore } from '@/store'
import { useToast } from 'vue-toastification/composition'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore()
    const toast = useToast()

    const companyId = inject('companyFromPage')

    const isCurrentUserOwner = computed(() => store.state.Profile.company?.id === companyId || false)

    function onVitrineItemClick() {
      emit('on-vitrine-item-click', false) // TODO
    }

    function onBtnChangeClick() {
      if (isCurrentUserOwner.value) {
        emit('on-btn-change-click', props.item)
      } else {
        toast.error('NOT ALLOWED')
      }
    }

    return {
      isCurrentUserOwner,

      onVitrineItemClick,
      onBtnChangeClick,
    }
  },
})
